<template>
	<div class="wrap">
		<div class="title">{{info.title}}</div>
		<div class="standard">
			<div class="sub-title">诊断标准</div>
			<div class="info" :class="standardOn ? 'on' : ''" v-html="info.criteria"></div>
			<div class="standard-oper" @click="standardOn = !standardOn"><img :src="!standardOn ? openImg : closeImg"></div>
		</div>
		<div class="identify">
			<div class="sub-title">常见病因的鉴别</div>
			<div class="identify-type">
				<div class="type-name">
					<svg xmlns="http://www.w3.org/2000/svg" width="92.952" height="20.472" viewBox="0 0 92.952 20.472">
						<path id="路径_1547" data-name="路径 1547" d="M17.136-.552,19.608-1.3l-.576-6.72-2.76.1.1,2.04-.192.6-.12.192L18.672-3,17.256-1.224,14.9-3.1,13.176-.144,11.232-1.32l2.856-4.8-.072-1.728-3,.072V-.36H8.736V-8.88l1.1-1.152,4.08-.1-.048-1.344-4.776.12-.048-2.232,11.832-.36.072,2.28L16.1-11.52l.048,1.32,3.864-.1,1.176,1.032.744,8.688L21.168.6l-3.36,1.008ZM1.3-6.312l3.72-2.064.144-2.016-2.592-2.4,1.512-1.68L5.352-13.3l.168-2.52,1.128-1.056,7.3-.168-.384-1.1,2.136-.72.6,1.752,6.36-.144.072,2.28-15,.36L6.672.984,4.416.84l.432-6.528L2.4-4.32ZM28.248.432l-1.08-1.1-.384-15.336,1.056-1.128,16.176-1.176,1.224,1.128L44.784-.024l-1.2,1.08Zm14.28-1.68.408-14.736L29.064-14.976l.336,13.2ZM30-11.328l4.656-.48,1.92-3.264,1.944,1.128L37.44-12.1l4.464-.456.24,2.256-6.12.624L32.016-2.784,30.024-3.912l3.192-5.472-2.976.312Zm6.1,4.3L37.7-8.616l3.552,3.624L39.624-3.408ZM65.832-8.04l.048,2.4,3.744-.576.336,2.232-4.992.792-1.32-1.1-.1-3.6-2.784.168-1.56,5.376-2.16-.624,1.32-4.608L55.8-7.44,55.632-9.7l5.592-.336v-3.1l-3.384.672L56.52-13.8l.888-4.08,2.184.48-.5,2.4,2.136-.432.024-3.36H63.5v2.9l4.9-.984.432,2.232L63.5-13.584v3.408l5.808-.384.168,2.28ZM53.112-2.592,55.32-.912,69.984-2.064,70.152.168,55.08,1.368l-.792-.192-1.8-1.368-.456,1.68-2.184-.6,3.144-11.664-2.64.456-.384-2.232L54.36-13.3l1.272,1.416Zm.312-11.328L50.16-17.16l1.584-1.584,3.264,3.24Zm26.208,1.7.192,3.5,1.1-.384.72,2.16-1.7.576L80.28.12,78.912,1.272,75.168.36,75.7-1.8l2.256.552-.24-4.368L74.52-4.536,73.776-6.7l3.816-1.272-.24-4.176-3.12.12-.1-2.256,3.1-.12-.216-4.3,2.28-.12.216,4.32,1.44-.072.1,2.28Zm10.248-.528,4.176,1.464-.744,2.136-5.9-2.088-4.3,2.664-1.2-1.92,2.76-1.7L82.2-13.08l.768-2.136,4.176,1.488L90.312-15.7l-6.792-.912.312-2.256L93.72-17.5l.432,2.088ZM89.256-1.368l.1,2.9L87.1,1.584l-.072-2.712-5.3.552-.24-2.232,5.472-.6L86.9-5.976,83.088-5.9l-.072-2.28,3.816-.048-.024-1.512,2.232-.072.048,1.536,3-.048L92.136-6.1l-3,.072.072,2.376,4.8-.528.24,2.256Z" transform="translate(-1.296 18.864)" fill="#49fcfc"/>
					</svg>
				</div>
				<ul class="type1-select">
					<li :class="type1.state ? 'on' : ''" v-for="(item, i) in type1.select" :key="i" :data-id="item.id" @click="delType('type1', i)">{{item.title}}<img src="../assets/img/disease/del.png"></li>
				</ul>
				<div class="type1-add" @click="type1.state = true"><img src="../assets/img/disease/add-cause.png" alt=""></div>
				<ul class="type1-list" :class="type1.state ? 'on' : ''">
					<li v-for="(item, i) in type1.list" :key="i" :data-id="item.id" @click="addType('type1', i)">{{item.title}}</li>
				</ul>
				<div class="type-list-close" v-if="type1.state" @click="type1.state = false"><img src="../assets/img/disease/close.png" alt=""></div>
			</div>
			<div class="identify-type">
				<div class="type-name">
					<svg xmlns="http://www.w3.org/2000/svg" width="90.696" height="20.952" viewBox="0 0 90.696 20.952">
						<path id="路径_1548" data-name="路径 1548" d="M4.248.432l-1.08-1.1L2.784-16.008,3.84-17.136l16.176-1.176,1.224,1.128L20.784-.024l-1.2,1.08Zm14.28-1.68.408-14.736L5.064-14.976,5.4-1.776ZM6-11.328l4.656-.48,1.92-3.264,1.944,1.128L13.44-12.1l4.464-.456.24,2.256-6.12.624L8.016-2.784,6.024-3.912,9.216-9.384,6.24-9.072Zm6.1,4.3L13.7-8.616l3.552,3.624L15.624-3.408Zm21.912-3.144-.936.888L40.56-9.48l.384,2.208-4.272,1.44,4.992-.144-.432-.888,2.016-1.008,1.824,3.6L43.08-3.264l-.264-.48L37.968-3.6l.6,3.1L37.632.816,33.72,1.44,33.36-.768l2.784-.456-.456-2.328-6.576.192-.384-2.208,4.32-1.44-2.856.048L29.4-8.88l1.416-1.368-3.888-.1L27-12.6l8.04.192v-.456l-5.448-.12.072-2.256,5.376.12v-.48l-6.552.192-.048-2.256,6.6-.192v-.816h2.28v.744l6.432-.192.072,2.256-6.528.192v.6l5.376.12-.048,2.232-5.352-.1v.456l7.392.168-.072,2.28Zm-7.2,9.624,5.016-2.856,1.1,1.968L27.96,1.392Zm13.08-.936.96-2.04L45.192-1.44,44.208.6ZM55.872-6.312l5.016,5.16L59.28.456,54.816-4.176l-2.688,5.4L50.088.216,53.136-5.9,50.4-8.76l1.608-1.56,2.184,2.28,2.52-5.04L50.9-14.664l.6-2.16,7.128,1.92.72,1.584ZM68.064-14.04,69.144-.384l-.936,1.2-5.88,1.032L61.92-.36l4.872-.912-.984-12.552-5.616.552L60-15.5l5.64-.552-.216-2.9L67.68-19.1l.216,2.832,1.824-.192.192,2.232ZM63.792-3.312l-3.6-6.96L62.208-11.3l3.6,6.984ZM84.36-.384l-.192-17.568L86.448-18l.048,5.04,5.784-3,1.056,2.016-6.816,3.5.1,8.616L92.928-3.36l.552,2.208L85.728.72ZM74.544-.408,75.7-17.352l2.232.144-.288,4.176,5.808-.552.24,2.256-6.192.6-.552,8.544,5.184-2.5.96,2.04L76.176.7Z" transform="translate(-2.784 19.104)" fill="#49fcfc"/>
					</svg>
				</div>
				<ul class="type2-select">
					<li v-for="(item, i) in type2.select" :key="i" :data-id="item.id" @click="delType('type2', i)">{{item.title}}<img src="../assets/img/disease/select.png"></li>
				</ul>
				<div class="type2-add" @click="type2.state = true"><img src="../assets/img/disease/add-contrast.png" alt=""></div>
				<ul class="type2-list" :class="type2.state ? 'on' : ''">
					<li v-for="(item, i) in type2.list" :key="i" :data-id="item.id" @click="addType('type2', i)">{{item.title}}</li>
				</ul>
				<div class="type-list-close" v-if="type2.state" @click="type2.state = false"><img src="../assets/img/disease/close.png" alt=""></div>
			</div>
			<div class="identify-wrap" v-if="identify.length > 0">
				<ul>
					<li class="identify-list" v-for="(item, i) in identify" :key="i">
						<div class="identify-list-name">
							<div>{{item.ftitle}}</div>
							<div @click="item.on = !item.on"><img :src="item.on ? closeImg : openImg" alt=""></div>
						</div>
						<ul class="identify-list-info" :class="item.on ? 'on' : ''">
							<li v-for="(subItem, i1) in item.list" :key="i1">
								<div>{{subItem.ctitle}}</div>
								<div>{{subItem.content}}</div>
							</li>
						</ul>
					</li>
				</ul>

			</div>
		</div>
		<div v-if="info.questionnaire_id > 0">
			<div class="assess" @click="toQA">
				<div>{{info.questionnaire_title}}</div>
			</div>
			<div class="treat" @click="toQA">开始计算 ></div>
		</div>

		<!-- 参考文献 -->
		<Reference :info="refInfo"></Reference>
		<div class="copyright"><a href="https://beian.miit.gov.cn/#/home">沪ICP备13026779号-18&nbsp;&nbsp;&nbsp;&nbsp;</a><img src="../assets/img/gongAn.png">&nbsp;<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009903">沪公网安备31010402009903号</a></div>
	</div>
</template>

<script>
import { Toast } from 'vant'
import { getDiagnosisDetail, getFactorContent } from '../common/api/index'
import Reference from '../components/Reference'
export default {
  data() {
    return {
			id: '',
			info: {},
			refInfo: "",
			standardOn: false,
			openImg: require('../assets/img/disease/open.png'),
			closeImg: require('../assets/img/disease/close.png'),
			type1: {
				state: true,
				cause: [],
				select: [],
				list: []
			},
			type2: {
				state: true,
				cause: [],
				select: [],
				list: []
			},
			identify: []
		};
  },
	components: {
		Reference
	},
  created() {
		let _type = 1
		this.id = this.$route.query.id
		if (this.$route.query.type) {
			_type = this.$route.query.type
		}
		this.findDetail()

		// 访问记录
		this.$Common._record({type: _type, openid: this.$store.state.openid, module_id: 3, category_id: this.id, article_id: this.id, page: this.$route.path})
	},
  mounted() {
		this.$wxShare._wxConfigJSSDK()
	},
  methods: {
		/**
		 * 获取病例详情
		 */
		findDetail () {
			Toast.loading({
        message: '',
        forbidClick: true,
        duration: 0
      })
			let that = this
			getDiagnosisDetail({id: that.id}).then(res => {
				Toast.clear()
				// console.log(res)
				if (res.code === 200) {
					that.info = res.data.cause_diagnosis_detail
					that.refInfo = res.data.cause_diagnosis_detail.reference

					let cause = res.data.cause_diagnosis_detail.cause
					let cause_factor = res.data.cause_diagnosis_detail.cause_factor
					that.type1.list = JSON.parse(JSON.stringify(cause))
					that.type1.cause = JSON.parse(JSON.stringify(cause))
					that.type2.list = JSON.parse(JSON.stringify(cause_factor))
					that.type2.cause = JSON.parse(JSON.stringify(cause_factor))
				} else {
					Toast(res.msg)
				}
			})
		},

		/**
		 * 新增病因
		 */
		addType (type, index) {
			this[type].select.push(this[type].list[index])
			this[type].list.splice(index, 1)
			this.getFactor()
		},

		/**
		 * 删除病因
		 */
		delType (type, index) {
			this[type].select.splice(index, 1)
			if (this[type].select.length > 0) {
				this[type].list = this.arrDiff(this[type].cause, this[type].select)
			} else {
				this[type].list = JSON.parse(JSON.stringify(this[type].cause))
				this.identify = []
			}
			this.getFactor()
		},

		/**
		 * 数组相减的方法
		 */
		arrDiff (a, b) {
			return a.map(JSON.stringify).concat(b.map(JSON.stringify)).filter((v, i, arr) => {
				return arr.indexOf(v) === arr.lastIndexOf(v)
			}).map(JSON.parse)
		},

		/**
		 * 跳转问卷
		 */
		toQA () {
			// console.log(this.info.questionnaire_id)
			let id = this.info.questionnaire_id
			this.$router.push('/info?id=' + id)
		},

		/**
		 * 获取鉴别诊断数据对比
		 */
		getFactor () {
			if (this.type1.select.length > 0 && this.type2.select.length > 0) {
				this.$Common._record({openid: this.$store.state.openid, module_id: 3, category_id: this.id, article_id: this.id, page: this.$route.path})

				Toast.loading({
					message: '',
					forbidClick: true,
					duration: 0
				})
				let that = this
				let cause = []
				let cause_factor = []
				this.type1.select.forEach(function (value) {
					cause.push(value.id)
				})
				this.type2.select.forEach(function (value) {
					cause_factor.push(value.id)
				})
				getFactorContent({
					cause: cause,
					cause_factor: cause_factor
				}).then(res => {
					Toast.clear()
					if (res.code === 200) {
						console.log(res)
						let identify = res.data.cause_factor_content
						identify.forEach(function (value) {
							value.on = true
						})
						that.identify = identify
					} else {
						Toast(res.msg)
					}
				})
			}
		},

		/**
		 * 展开关闭鉴别诊断数据对比
		 */
		onOper (i) {
			this.identify[i].on = !this.identify[i].on
		}
	}
};
</script>

<style scoped lang="scss">
.copyright{
	position: relative;
	bottom: initial;
	left: initial;
	margin-top: 20px;
}
.wrap{
	padding-bottom: 40px;
}
.title{
	width: 70%;
	font-size: 40px;
	text-align: center;
	margin: auto;
	margin-top: 94px;
	position: relative;

	&::before{
		content: '';
		width: 100%;
		height: 4px;
		background: url('../assets/img/title_line.png') top center no-repeat;
		background-size: 100% 100%;
		position: absolute;
		bottom: 0;
		left: 0;
	}
}
.sub-title{
	width: 70%;
	font-size: 30px;
	// font-size: 26px;
	font-weight: bold;
	color: #49FCFC;
	margin-left: 50px;
	padding-bottom: 10px;
	position: relative;
	margin-top: 50px;

	&::before{
		content: '';
		width: 100%;
		height: 4px;
		background: url('../assets/img/title_line_1.png') top center no-repeat;
		background-size: 100% 100%;
		position: absolute;
		bottom: 0;
		left: 0;
	}
}
.sub-title-1{
	margin-top: 30px;
}
.standard{
	position: relative;
	.info{
		width: 86%;
		height: 36px;
		overflow: hidden;
		font-size: 28px;
		line-height: 1.5;
		// font-size: 26px;
		margin: 20px 0 0 59px;
		line-height: 40px;
		transition: all 0.5s linear;

		&.on{
			height: auto;
		}
	}

	.standard-oper{
		width: 86px;
		// width: 72px;
		position: absolute;
		top: 30px;
		right: 42px;
		z-index: 2;

		img{
			width: 100%;
		}
	}
}
.identify{
	.identify-type{
		width: 86%;
		margin: auto;
		position: relative;
		.type-name{
			margin-top: 20px;
			svg{
				width: 130px;
				// width: 100px;
			}
		}

		.type1-select,.type1-list{
			display: flex;
			flex-wrap: wrap;
			font-size: 24px;
			// font-size: 22px;

			li{
				display: flex;
				align-items: center;
				margin: 14px 26px 0 0;
				text-decoration: underline;

				img{
					width: 15px;
					margin-left: 10px;
				}
			}
		}

		.type1-select{
			li{
				&.on{
					color: #49FCFC;
					text-decoration-color: #49FCFC;
				}
			}
		}

		.type1-add,.type2-add{
			width: 140px;
			// width: 110px;
			margin: auto;
			img{
				width: 100%;
				margin-top: 14px;
			}
		}

		.type2-select,.type2-list{
			display: flex;
			flex-wrap: wrap;
			font-size: 24px;
			// font-size: 22px;

			li{
				display: flex;
				align-items: center;
				margin: 14px 36px 0 0;
				background: rgba(73, 252, 252, 0.3);
				border-radius: 22px;
				padding: 0 5px 0 16px;

				img{
					width: 24px;
				}
			}
		}

		.type1-list,.type2-list{
			height: 0;
			overflow: hidden;

			&.on{
				height: auto;
			}
		}

		.type2-list{
			li{
				background: transparent;
			}
		}

		.type-list-close{
			width: 86px;
			// width: 72px;
			position: absolute;
			bottom: 0;
			right: 0;
			img{
				width: 100%;
			}
		}
	}

	.identify-wrap{
		width: 92%;
		min-height: 634px;
		margin-left: 5%;
		margin-top: 38px;
		position: relative;
		border: 3px solid #fff;
		background: #000;
		box-sizing: border-box;
		padding-bottom: 5px;
		z-index: 2;

		&:after{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 5px;
			left: 5px;
			border: 3px solid #fff;
			z-index: 1;
		}

		>ul{
			width: 100%;
			min-height: 634px;
			border: 3px solid #fff;
			background: #000;
			margin-top: -10px;
			margin-left: -10px;
			position: relative;
			z-index: 3;
			padding-bottom: 30px;

			&:before{
				content: '';
				width: 95.5%;
				height: 35px;
				position: absolute;
				top: -17px;
				left: 2%;
				background: url('../assets/img/disease/bg-top.png') top center no-repeat;
				background-size: 100% 100%;
			}
		}
		.identify-list{
			width: 94%;
			margin: auto;
			margin-top: 40px;

			.identify-list-name{
				background: rgba(73, 252, 252, 0.3);
				font-size: 24px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-radius: 26px;
				padding: 6px 11px;

				div:last-child{
					display: flex;
					align-items: center;

					img{
						width: 72px;
					}
				}
			}

			.identify-list-info{
				font-size: 26px;
				height: 0;
				overflow: hidden;

				&.on{
					height: auto;
				}
				li{
					margin-top: 10px;
					div:first-child{
						color: #49FCFC;

					}
				}
			}
		}
	}
}
.assess{
	width: 89%;
	height: 97px;
	line-height: 83px;
	font-size: 40px;
	color: #fff;
	text-align: center;
	background: url('../assets/img/btn_bg.png') top center no-repeat;
	background-size: 100% 100%;
	margin: auto;
	margin-top: 35px;
}
.treat{
	font-size: 32px;
	text-align: center;
	margin-top: 30px;
	color: #FFDA00;
}
</style>
